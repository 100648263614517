<template>
  <div id="header-nav" v-if="$route.meta.displayNavBar">
    <router-link to="/home" class="nav-link">Home</router-link>
    <router-link to="/air-quality" class="nav-link">Air-Quality</router-link>
  </div>
  <router-view/>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style lang="scss" scoped>
#header-nav {
  display: flex;
  justify-content: center;

  .nav-link {
    margin: 1em;
  }
}
</style>