<template>
  <div id="footer">
    <router-link to="/credits" class="nav-link">Credits</router-link>
    <router-link to="/login" class="nav-link">Login</router-link> <!--TODO: remove this-->
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
#footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;

  .nav-link {
    margin: 1em;
  }
}

</style>