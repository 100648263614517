<template>
  <main id="content">
    <Header></Header>
    <Footer></Footer>
  </main>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {Footer, Header}
}
</script>

<style>
#content {
  width: 100%;
}
</style>
