import {createWebHistory, createRouter} from "vue-router";
import LoginPage from "@/components/Login-Page";
import HomePage from "@/components/Home-Page";
import NotFound from "@/components/NotFound";
import Credits from "@/components/Credits";
import AirQuality from "@/components/Air-Quality";

const routes = [
    {
        path: "/",
        redirect: 'login',
        meta: {displayNavBar: false}
    }, {
        path: "/home",
        name: "Home",
        component: HomePage,
        meta: {displayNavBar: true}
    },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
        meta: {displayNavBar: false}
    }, {
        path: "/air-quality",
        name: "Air-Quality",
        component: AirQuality,
        meta: {displayNavBar: true}
    }, {    }, {
        path: "/credits",
        name: "Credits",
        component: Credits,
        meta: {displayNavBar: false}
    }, {
        path: "/:catchAll(.*)",
        component: NotFound,
        meta: {displayNavBar: false}
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;