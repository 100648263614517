<template>
  <div id="login-page">
    <div class="img-container">
      <img src="../assets/img/login-background.png" alt="smarthome background img">
      <div class="login-form">
        <form>
          <div class="form-pair">
            <label for="username" class="label-form">Username</label>
            <input id="username" v-model="username" placeholder="Username"/>
          </div>
          <div class="form-pair">
            <label for="password" class="label-form">Password</label>
            <input id="password" v-model="password" type="password" placeholder="************"/>
          </div>
          <div class="form-pair">
            <button @click="loginButtonClicked($event)" class="default-button">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login-Page",
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    loginButtonClicked(event) {
      // avoid redirect
      event.preventDefault()
      console.log(this.username)
      console.log(this.password)
      this.$router.push('home')
    }
  },
}
</script>

<style lang="scss" scoped>
#login-page {
  .img-container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    img {
      min-width: 100vw;
    }


    .login-form {
      position: absolute;
      top: 50%;

      form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100vw;
        margin-top: -15em;


        .form-pair {
          display: flex;
          flex-direction: column;
          margin: 1em;

          .label-form {
            color: white;
            font-size: 3em;
            margin-bottom: 0.25em;
          }

          input {
            height: 2.5em;
            border-radius: 10px;
            padding: 5px;
          }

        }
      }
    }
  }
}
</style>