<template>
  <div>
    <h1>Home-Page is here</h1>
  </div>
</template>

<script>

export default {
  name: "Home-Page"
}
</script>

<style lang="scss" scoped>

</style>