<template>
  <div>
    <a href="https://www.flaticon.com/free-icons/smart-home" title="smart home icons">Smart home icons created by
      Freepik - Flaticon</a>
    <a href="https://www.freepik.com/vectors/background">Background vector created by pikisuperstar -
      www.freepik.com</a>
  </div>
</template>

<script>
export default {
  name: "Credits"
}
</script>

<style lang="scss" scoped>

</style>