<template>
  <div class="last-humidity-content">
    <h1>ID: {{ lastHumidity.id }}</h1>
    <h1>Time: {{ lastHumidity.time_stamp }}</h1>
    <h1>Temperature: {{ lastHumidity.temperature }}</h1>
    <h1>Humidity: {{ lastHumidity.humidity }}</h1>
  </div>
</template>

<script>
import SensorService from "@/services/SensorService";

export default {

  name: "Air-Quality",
  data() {
    return {
      lastHumidity: [],
    };
  },
  methods: {
    retrieveData() {
      SensorService.getLastHumidity()
          .then(response => {
            this.lastHumidity = response.data.message[0];
            this.lastHumidity.time_stamp = this.convertTZ(this.lastHumidity.time_stamp, 'CET');
          })
          .catch(e => {
            console.log(e);
          });
    },
    convertTZ(date) {
      return new Date(date).toLocaleString("de-DE");
    }
  },
  mounted() {
    this.retrieveData();
  }
}
</script>

<style lang="scss" scoped>
.last-humidity-content {
  text-align: center;
}
</style>